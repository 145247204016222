import { isEmpty } from "lodash"
import { toolsConstants, toolsService } from "."
import { alertActions } from "../alert"
import { applicationActions } from "../applicationManager"

/**
 * Active Questionnaire UID
 *
 * @param data Object Questionnaire UID
 */
const exportQuestionnaireUID = (suveryData) => {
  return (dispatch) => {
    dispatch(success(suveryData))
  }
  function success(data) {
    return { type: toolsConstants.EXPORT_QUESTIONNAIRE_UID, data }
  }
}

/**
 * Active Questionnaire UID
 *
 * @param data Object Questionnaire UID
 */
const exportFileFormat = (format) => {
  return (dispatch) => {
    dispatch(success(format))
  }
  function success(data) {
    return { type: toolsConstants.EXPORT_FILE_FORMAT, data }
  }
}

/**
 * Active Questionnaire UID
 *
 * @param data Object Questionnaire UID
 */
const exportIncludeStatus = (status) => {
  return (dispatch) => {
    dispatch(success(status))
  }
  function success(data) {
    return { type: toolsConstants.EXPORT_INCLUDE_STATUS, data }
  }
}

/**
 * Export Applications Action
 *
 * @param uid Questionnaire UID
 */
const exportApplications = (payload) => {
  const {
    exportFormat: format,
    surveyUuid,
    includeStatus,
    surveyName,
  } = payload
  return (dispatch) => {
    dispatch(request())
    try {
      toolsService
        .exportApplications({
          format,
          surveyUuid,
          includeStatus: !!includeStatus,
        })
        .then(
          (data) => {
            // eslint-disable-next-line no-unused-expressions
            !isEmpty(data) && dispatch(success(data, surveyName))
          },
          (error) => {
            dispatch(failure(error))
            dispatch(
              alertActions.error(
                "There's no data of the survey you selected so an export file could not be generated."
              )
            )
          }
        )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: toolsConstants.EXPORT_APPLICATIONS_REQUEST }
  }
  function success(data, surveyName) {
    return {
      type: toolsConstants.EXPORT_APPLICATIONS_SUCCESS,
      data,
      surveyName,
    }
  }
  function failure(error) {
    return { type: toolsConstants.EXPORT_APPLICATIONS_FAILURE, error }
  }
}

/**
 * Import Bulk Applications
 *
 * @param {*} file
 */
function getAllImportBulkApplications() {
  return (dispatch) => {
    dispatch({
      type: toolsConstants.IMPORT_APPLICATIONS_JOBS_REQUEST,
      data: {},
    })
    toolsService.getAllImportBulkApplications().then(
      (data) => {
        dispatch({
          type: toolsConstants.IMPORT_APPLICATIONS_JOBS_SUCCESS,
          data,
        })
      },
      (error) => {
        dispatch({
          type: toolsConstants.IMPORT_APPLICATIONS_JOBS_FAILURE,
          error,
        })
      }
    )
  }
}

/**
 * Import Bulk Applications
 *
 * @param {*} file
 */
function importBulkApplications(file) {
  return (dispatch) => {
    dispatch({
      type: toolsConstants.IMPORT_APPLICATIONS_BULK_REQUEST,
      data: {},
    })
    toolsService.importBulkApplications(file).then(
      (data) => {
        dispatch(alertActions.success("Upload successful"))
        dispatch({
          type: toolsConstants.IMPORT_APPLICATIONS_BULK_SUCCESS,
          data,
        })
      },
      (error) => {
        dispatch(alertActions.error("Upload failed", error))
        dispatch({
          type: toolsConstants.IMPORT_APPLICATIONS_BULK_FAILURE,
          error,
        })
      }
    )
  }
}

/**
 * Get Trigger Publisher sync
 */
function bulkInitApplications(surveyUid) {
  return (dispatch) => {
    dispatch(request())
    toolsService.bulkInitApplications(surveyUid).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success("Bulk Init complete."))
      },
      (errorMessage) => {
        dispatch(error(errorMessage))
        if (
          errorMessage.response.data &&
          errorMessage.response.data.message &&
          errorMessage.response.data.status_code === 400
        ) {
          dispatch(alertActions.error(errorMessage.response.data.message))
        }
      }
    )
  }

  function request(data) {
    return { type: toolsConstants.TOOLS_BULK_INIT_REQUEST, data }
  }
  function success(data) {
    return { type: toolsConstants.TOOLS_BULK_INIT_SUCCESS, data }
  }
  function error(data) {
    return { type: toolsConstants.TOOLS_BULK_INIT_FAILURE, data }
  }
}

function syncMusoniData(mfiId) {
  return (dispatch) => {
    dispatch(request())
    toolsService.syncMusoniData(mfiId).then(
      (res) => {
        dispatch(success(res))
        if (res?.status_code === 200 || res?.status_code === 201) {
          dispatch(alertActions.success(res?.message))
        }
      },
      (errorMessage) => {
        dispatch(error(errorMessage))
        if (
          errorMessage.response.data &&
          errorMessage.response.data.message &&
          errorMessage.response.data.status_code === 400
        ) {
          // eslint-disable-next-line no-unused-expressions
          errorMessage?.response?.data?.errors.map((err) => {
            return dispatch(alertActions.error("Failed: " + err))
          })
        }
      }
    )
  }

  function request(data) {
    return { type: toolsConstants.SYNC_MUSONI_DATA_REQUEST, data }
  }
  function success(data) {
    return { type: toolsConstants.SYNC_MUSONI_DATA_SUCCESS, data }
  }
  function error(data) {
    return { type: toolsConstants.SYNC_MUSONI_DATA_FAILURE, data }
  }
}

function repayMusoniLoan(data, collectionIds, selectedQuestionnaire) {
  return (dispatch) => {
    toolsService.repayMusoniLoan(data).then(
      (res) => {
        if (res?.status_code === 200 || res?.status_code === 201) {
          dispatch(alertActions.success(res?.message))
          dispatch(
            applicationActions.updateCollectionsStatus(
              collectionIds,
              selectedQuestionnaire
            )
          )
        }
      },
      (errorMessage) => {
        if (
          errorMessage.response.data &&
          errorMessage.response.data.message &&
          errorMessage.response.data.status_code === 400
        ) {
          // eslint-disable-next-line no-unused-expressions
          errorMessage?.response?.data?.errors.map((err) => {
            return dispatch(alertActions.error("Failed: " + err))
          })
        }
      }
    )
  }
}

function getRasterFiles() {
  return (dispatch) => {
    dispatch(request({}))
    toolsService.getRasterFiles().then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(
            alertActions.error("Fetch failed: " + error.response.data.message)
          )
        }
      }
    )
  }

  function request() {
    return { type: toolsConstants.GET_RASTER_FILES }
  }
  function success(data) {
    return { type: toolsConstants.GET_RASTER_FILES_SUCCESS, data }
  }
  function failure(error) {
    return { type: toolsConstants.GET_RASTER_FILES_FAILURE, error }
  }
}

function addRasterFile(payload) {
  return (dispatch) => {
    dispatch(request({}))
    toolsService.addRasterFile(payload).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(
            alertActions.error(
              "Creation failed: " + error.response.data.message
            )
          )
        }
      }
    )
  }

  function request() {
    return { type: toolsConstants.GET_RASTER_FILES }
  }
  function success(data) {
    return { type: toolsConstants.ADD_RASTER_FILES_SUCCESS, data }
  }
  function failure(error) {
    return { type: toolsConstants.GET_RASTER_FILES_FAILURE, error }
  }
}

function editHazardData(payload) {
  return (dispatch) => {
    dispatch(request({}))
    toolsService.editHazardData(payload).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(
            alertActions.error("Edit failed: " + error.response.data.message)
          )
        }
      }
    )
  }

  function request() {
    return { type: toolsConstants.GET_RASTER_FILES }
  }
  function success(data) {
    return { type: toolsConstants.EDIT_RASTER_FILES_SUCCESS, data }
  }
  function failure(error) {
    return { type: toolsConstants.GET_RASTER_FILES_FAILURE, error }
  }
}

function deleteRasterFile(file) {
  return (dispatch) => {
    toolsService.deleteRasterFile(file.id).then(
      (res) => {
        dispatch(alertActions.success("Image deleted successfully"))
        dispatch(toolsActions.getRasterFiles())
      },
      (error) => {
        dispatch(alertActions.error("Image deletion failed"))
        dispatch(toolsActions.getRasterFiles())
      }
    )
  }
}

function listActivitiesLog(data) {
  return async (dispatch) => {
    dispatch(request())
    try {
      const res = await toolsService.listActivitiesLog(data)
      dispatch(success())
      return res
    } catch (error) {
      alert(error)
      dispatch(error())
      throw error
    }
  }

  function request() {
    return { type: "ACTIVITY_LOG_REQUEST" }
  }

  function success() {
    return { type: "ACTIVITY_LOG_SUCCESS" }
  }

  function error() {
    return { type: "ACTIVITY_LOG_ERROR" }
  }
}

export const toolsActions = {
  exportQuestionnaireUID,
  exportFileFormat,
  exportIncludeStatus,
  exportApplications,
  getAllImportBulkApplications,
  importBulkApplications,
  bulkInitApplications,
  syncMusoniData,
  repayMusoniLoan,
  getRasterFiles,
  addRasterFile,
  deleteRasterFile,
  listActivitiesLog,
  editHazardData,
}
